import { LessonPathCard, LessonPathCardProps } from '@components/molecules';

interface LessonsPathProps {
  lessons: LessonPathCardProps[];
}

export const LessonsPath = ({ lessons }: LessonsPathProps) => {
  return (
    <div className='relative mb-24 w-full space-y-4'>
      <div className='absolute bottom-0 left-[28px] top-0 w-5 bg-purple-200 pt-8'></div>
      {lessons.map((lesson, index) => (
        <LessonPathCard {...lesson} key={lesson.publicUid} index={index} />
      ))}
    </div>
  );
};
