import { STATES_STR } from '@domain/constants';
import { TacoTruck, NotStarted, Skipped, Finished } from '@assets/img';
import { Typography } from '@components/atoms';
import { useDispatch } from 'react-redux';
import { setModalLesson } from '@store/slices';
import { MdDone, MdStar, MdSkipNext } from 'react-icons/md';

export interface LessonPathCardProps {
  publicUid: string;
  state: STATES_STR;
  title: string;
  description: string;
  className?: string;
  index: number;
  videoLinkOne?: string;
  videoLinkTwo?: string;
}

export const LessonPathCard = ({
  state,
  title,
  index,
  description,
  publicUid,
  videoLinkOne,
  videoLinkTwo,
}: LessonPathCardProps) => {
  const dispatch = useDispatch();

  const stateText = {
    [STATES_STR.notStarted]: {
      image: NotStarted,
      text: 'Start',
      style:
        'text-secondary-achievements-700 border-secondary-achievements-700',
      icon: <MdStar className='mr-2 text-secondary-achievements-700' />,
    },
    [STATES_STR.inProgress]: {
      image: TacoTruck,
      text: 'In Progress',
      style:
        'text-secondary-achievements-500 border-secondary-achievements-500',
      icon: <MdDone className='mr-2 text-secondary-achievements-500' />,
    },
    [STATES_STR.skipped]: {
      image: Skipped,
      text: 'Skipped',
      style: 'text-gray-400 border-gray-400',
      icon: <MdSkipNext className='mr-2 text-gray-400' />,
    },
    [STATES_STR.finished]: {
      image: Finished,
      text: 'Completed',
      style:
        'text-secondary-semantic-success-400 border-secondary-semantic-success-400',
      icon: <MdDone className='mr-2 text-secondary-semantic-success-400' />,
    },
  };

  const handleClickLesson = () => {
    dispatch(
      setModalLesson({
        isOpen: true,
        title,
        description,
        publicUid,
        state,
        videoLinkOne,
        videoLinkTwo,
      })
    );
  };

  return (
    <div
      className='hover:shadow-lg relative mx-3 flex max-w-full cursor-pointer items-center rounded-lg bg-secondary-surface-200 p-4 shadow-general transition'
      onClick={handleClickLesson}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex items-center pr-1'>
          <h1 className='mr-4 font-heading text-2xl font-bold text-gray-400'>
            {String(index + 1).padStart(2, '0')}
          </h1>
          <Typography variant='h5' className='text-gray-600'>
            {title}
          </Typography>
        </div>
        <div className='flex flex-col items-center'>
          <img
            src={stateText[state].image}
            alt={state}
            className='mb-2 h-8 w-8'
          />
          <div
            className={`flex w-24 items-center justify-center rounded-full border px-3 py-1 text-[10px] font-medium ${stateText[state].style}`}
          >
            {stateText[state].icon}
            {stateText[state].text}
          </div>
        </div>
      </div>
    </div>
  );
};
