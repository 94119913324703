import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import {
  PollActivityInput,
  PollActivityResponses,
} from '@components/molecules';
import { useQuiz } from '@hooks/index';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActivitySelectors, PreviewModeProps } from '@store/slices';

interface RenderQuizActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
  previewMode: PreviewModeProps;
}

export const RenderQuizActivity = ({
  slide,
  role,
  channel,
  showResults,
  previewMode,
}: RenderQuizActivityProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const isActivityProjected = useSelector(
    ActivitySelectors.getIsActivityProjected
  );
  const [showCorrectAnswer, setShowCorrectAnswer] =
    useState(!isActivityProjected);

  // Only use PubNub hook if not in preview mode
  const { quizResponses, sendQuizResponse } = previewMode.isPreviewMode
    ? {
        quizResponses: [],
        sendQuizResponse: (option: string) => {
          setSelectedOption(option);
        },
      }
    : useQuiz({
        channel,
        slideId: slide.id,
      });

  useEffect(() => {
    if (isActivityProjected) setShowCorrectAnswer(showResults);
  }, [showResults, isActivityProjected]);

  const handleOptionClick = (option: string) => {
    if (previewMode.isPreviewMode) {
      setSelectedOption(option);
    } else {
      sendQuizResponse(option);
    }
  };

  return (
    <SlideContentWrapper
      title={slide.quiz!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {(role === UserRole.CATECHIST && !previewMode.isPreviewMode) ||
      (previewMode.isPreviewMode && !previewMode.isStudentView) ? (
        <PollActivityResponses
          data={quizResponses}
          options={slide.quiz!.options}
          correctAnswer={slide.quiz?.options[slide.quiz?.correctOption]}
          showCorrectAnswer={showCorrectAnswer}
        />
      ) : !showResults || previewMode.isStudentView ? (
        <PollActivityInput
          options={slide.quiz!.options}
          correctAnswer={slide.quiz?.options[slide.quiz?.correctOption]}
          onClickOption={handleOptionClick}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <PollActivityResponses
          data={quizResponses}
          options={slide.quiz!.options}
          correctAnswer={slide.quiz?.options[slide.quiz?.correctOption]}
          showCorrectAnswer={showCorrectAnswer}
        />
      )}
    </SlideContentWrapper>
  );
};
